import React, { useState, FC } from 'react';
import { NavDropdown } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import Logo from './../../assets/SBS-Logo.svg';
import QR from './../../assets/qrIcons/qrMenu.png';
import BNPL from './../../assets/BNPL.svg';
import finbay from './../../assets/finbay.svg';
import sliceTop from './../../assets/sliceTop.svg';
import finpayHome from './../../assets/finbay.svg';
import './style.css';
import './new-mobile.scss';

interface Props {
	type: 'qr' | 'bnpl' | 'finpay' | '';
}

const MobileMenu: FC<Props> = ({ type }) => {

	const animateItems = () => {
		const el = document.getElementById("products-items");
		el?.classList.toggle('show-products-items')
		console.log(el)
	}

	return (
		<div className="mobile-side-menu">
			<div className="menu-image">
				{type === '' && (
					<Link className="logo-container" to="/">
						<img src={Logo} alt="logo" />
					</Link>
				)}
				{type === 'finpay' && (
					<Link className="logo-container" to="/">
						<img src={finpayHome} alt="finpayHome" />
					</Link>
				)}
				{type === 'qr' && (
					<Link className="logo-container" to="/">
						<img src={QR} alt="logo" />
						<span className="qr-style">QR code payment solution</span>
					</Link>
				)}
				{type === 'bnpl' && (
					<Link className="logo-container" to="/">
						<img src={BNPL} alt="logo" />
					</Link>
				)}
			</div>
			{/* new sub menu */}
			<section className="banner">
				<input type="checkbox" id="menu-control" className="menu-control" />

				<aside className="sidebar">
					<nav className="sidebar__menu">
						<Link className="navbar-links _Ubuntu-Medium" to="/services">
							Our services
						</Link>
						<div>
							<span className="navbar-links _Ubuntu-Medium">
								<span onClick={animateItems}>Our products</span>
									<ul id='products-items'>
										<li className="navbar-links _Ubuntu-Medium">
											<Link className="navbar-links _Ubuntu-Medium" to="/qr">
												<img width="24px" height="24px" src={QR} alt="Qr" />
												<span>QR code payment solution</span>
											</Link>
										</li>
										<li className="navbar-links _Ubuntu-Medium">
											<Link className="navbar-links _Ubuntu-Medium" to="/bnpl">
												<img
													width="55px"
													height="55px"
													src={BNPL}
													alt="BNPL"
													style={{ marginLeft: '-6px' }}
												/>
												<span>Buy Now Pay Later Solution</span>
											</Link>
										</li>
										<li className="navbar-links _Ubuntu-Medium">
											<Link className="navbar-links _Ubuntu-Medium" to="/finpay">
												<img
													width="55px"
													height="55px"
													src={finbay}
													alt="finbay"
													style={{ marginLeft: '-6px' }}
												/>
												<span>Buy Now Pay Later Solution</span>
											</Link>
										</li>
									</ul>
							</span>
						</div>
						<Link className="navbar-links _Ubuntu-Medium" to="/about-us">
							About us
						</Link>
						<Link className="navbar-links _Ubuntu-Medium" to="/contact-us">
							Contact us
						</Link>
					</nav>

					<label htmlFor="menu-control" className="sidebar__close" />
				</aside>
			</section>
			{/* new sub menu */}
			<div className="menu-items">
				<div className="burger-menu">
					{type === '' && (
						<label htmlFor="menu-control" className="hamburger">
							<i className="hamburger__icon" />
							<i className="hamburger__icon" />
							<i className="hamburger__icon" />
						</label>
					)}
					{type === 'finpay' && (
						<label htmlFor="menu-control" className="hamburger">
							<i className="hamburger__icon" />
							<i className="hamburger__icon" />
							<i className="hamburger__icon" />
						</label>
					)}
					{type === 'qr' && (
						<label htmlFor="menu-control" className="hamburger">
							<i className="hamburger__icon" />
							<i className="hamburger__icon" />
							<i className="hamburger__icon" />
						</label>
					)}
					{type === 'bnpl' && (
						<label htmlFor="menu-control" className="hamburger">
							<i className="hamburger__icon" />
							<i className="hamburger__icon" />
							<i className="hamburger__icon" />
						</label>
					)}
				</div>
				<div className={`menu menu-display`}>
					<ul>
						<li>
							<NavDropdown title="Our products" id="navbarScrollingDropdown">
								<img src={sliceTop} alt="Slice" className="slice-top" />
								<NavDropdown.Item>
									<Link to="/qr" className="to-projects">
										<div className="menu-item">
											<img src={QR} alt="Qr" />
											<p>QR code payment solution</p>
										</div>
									</Link>
								</NavDropdown.Item>
								<NavDropdown.Divider />
								<NavDropdown.Item>
									<Link to="/bnpl" className="to-projects">
										<img src={BNPL} alt="BNPL" />
									</Link>
								</NavDropdown.Item>
								<NavDropdown.Divider />
								<NavDropdown.Item>
									<Link to="/finpay" className="to-projects">
										<img src={finbay} alt="finbay" />
									</Link>
								</NavDropdown.Item>
							</NavDropdown>
						</li>
					</ul>
				</div>
			</div>
		</div>
	);
};

export default MobileMenu;