import React, { FC } from "react";
import "./style.css";

interface Props {
  title: string;
  icon: string;
}

const IconCard: FC<Props> = ({ title, icon }) => {
  return (
    <div className="icon-card-container">
      <img className='animate__animated animate__fadeIn animate__delay-1s' src={icon} alt="icon" />
      <p style={{ width: '100%' }} className="_Ubuntu-Medium">{title}</p>
    </div>
  );
};

export default IconCard;
