import React from 'react';
import Footer from '../../components/Footer';
import WebMenu from "../../components/WebMenu";
import MobileMenu from '../../components/MobileMenu'
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import sms from '../../assets/sms.svg';
import call from '../../assets/call.svg';
import location from '../../assets/location.svg'
import 'react-tabs/style/react-tabs.css';
import './Style.css';

const ContactUs = () => {
	return (
		<div className='contact-us'>
      <WebMenu />
      <MobileMenu type=''/>
			<div className="contact-header">
				<h2 className="_Ubuntu-Medium">Contact us</h2>
				<p>Great vision without great people is irrelevant. Let’s work together!</p>
			</div>
			<div className="contact-tabs">
				<Tabs>
					<TabList>
						<Tab>Saudi Arabia</Tab>
						{/* <Tab>USA</Tab> */}
            <Tab>Egypt</Tab>
					</TabList>

					<TabPanel>
						<div className='content'>
              <div className='info'>
                <ul>
                  <li>
                    <span>
                      <img src={sms} alt='sms' />
                    </span>
                    <span className='_Ubuntu-Medium' style={{ marginLeft: '12px' }}>info@sbs-sa.net</span>
                  </li>
                  <li>
                  <span>
                      <img src={call} alt='call' />
                    </span>
                    <span className='_Ubuntu-Medium' style={{ marginLeft: '12px' }}>+966 11 211 3300</span>
                  </li>
                  <li>
                  <span>
                      <img src={location} alt='location' />
                    </span>
                    <span className='_Ubuntu-Medium' style={{ marginLeft: '12px' }}>KSA-Riyadh- 7970 King Abdulaziz <br/>Alrabe 4478, Postal code: 13315</span>
                  </li>
                </ul>
              </div>
              <div className='map'>
              <iframe width='90%' height='350px' src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3621.571380043471!2d46.618416715523146!3d24.810126353381985!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3e2ee5505c7dea6b%3A0xf07b7e243c026656!2sBusiness%20Valley!5e0!3m2!1sen!2seg!4v1660928768492!5m2!1sen!2seg"></iframe>
              </div>
            </div>
					</TabPanel>
          {/* <TabPanel>
						<div className='content'>
              <div className='info'>
                <ul>
                  <li>
                    <span>
                      <img src={sms} alt='sms' />
                    </span>
                    <span className='_Ubuntu-Medium' style={{ marginLeft: '12px' }}>info@sbs-sa.net</span>
                  </li>
                  <li>
                  <span>
                      <img src={call} alt='call' />
                    </span>
                    <span className='_Ubuntu-Medium' style={{ marginLeft: '12px' }}>+966 11 211 3300</span>
                  </li>
                  <li>
                  <span>
                      <img src={location} alt='location' />
                    </span>
                    <span className='_Ubuntu-Medium' style={{ marginLeft: '12px' }}>30 N GOULD St Ste R Sheridab, WY 82801</span>
                  </li>
                </ul>
              </div>
              <div className='map'>
              <iframe width='90%' height='350px' src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2831.167610889619!2d-106.95709208413436!3d44.797772685682446!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x5335fabc2a6d206b%3A0xd7c55bd374d4b750!2s30%20N%20Gould%20St%20ste%20r%2C%20Sheridan%2C%20WY%2082801%2C%20USA!5e0!3m2!1sen!2seg!4v1660929504975!5m2!1sen!2seg"></iframe>
              </div>
            </div>
					</TabPanel> */}
          <TabPanel>
						<div className='content'>
              <div className='info'>
                <ul>
                  <li>
                    <span>
                      <img src={sms} alt='sms' />
                    </span>
                    <span className='_Ubuntu-Medium' style={{ marginLeft: '12px' }}>info@sbs-sa.net</span>
                  </li>
                  <li>
                  <span>
                      <img src={call} alt='call' />
                    </span>
                    <span className='_Ubuntu-Medium' style={{ marginLeft: '12px' }}>+966 11 211 3300</span>
                  </li>
                  <li>
                  <span>
                      <img src={location} alt='location' />
                    </span>
                    <span className='_Ubuntu-Medium' style={{ marginLeft: '12px' }}>6 Degla st, Maadi, Cairo 11742</span>
                  </li>
                </ul>
              </div>
              <div className='map'>
              <iframe width='90%' height='350px' src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3456.829836367554!2d31.269072215590395!3d29.955572829719433!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x1458380e89b5a06b%3A0x399d7020b045d970!2sDegla%20Square%2C%20Maadi%20as%20Sarayat%20Al%20Gharbeyah%2C%20Maadi%2C%20Cairo%20Governorate!5e0!3m2!1sen!2seg!4v1660929410152!5m2!1sen!2seg"></iframe>
              </div>
            </div>
					</TabPanel>
				</Tabs>
			</div>
			<Footer />
		</div>
	);
};

export default ContactUs;