import React, { FC } from "react";
import { Button, Col, Container, Form, Row } from "react-bootstrap";
import PhoneInput from "react-phone-number-input";
import "react-phone-number-input/style.css";
import "./style.css";

interface Props {
  title: string;
  content: string;
  haveImage: boolean;
  image?: string;
  type?: "qr" | "bnpl" | "finpay";
  btnTitle?: string;
}

const FormCard: FC<Props> = ({
  title,
  content,
  haveImage,
  image,
  type,
  btnTitle,
}) => {
  return (
    <div className="form-card">
      <Container
        className={type ? `${type} form-card-container` : "form-card-container"}
      >
        <Row>
          <Col className="get-col">
            <h2 className={type ? "hasType" : ""}>{title}</h2>
            <p className={type ? "hasType" : ""}>{content}</p>
            <Form>
              <input type="text" placeholder="Name" />
              <input type="email" placeholder="Email address" />
              <PhoneInput
                placeholder="Enter phone number"
                onChange={() => {}}
              />
              <input type="text" placeholder="Kind of bussniess" />
              <div className="button">
                <Button>{btnTitle ? btnTitle : "Send"}</Button>
              </div>
            </Form>
          </Col>
          {haveImage && (
            <Col className="have-image">
              <img src={image} alt="" />
            </Col>
          )}
        </Row>
      </Container>
    </div>
  );
};

export default FormCard;
