import React, { FC, useState } from "react";
import { Button, Col, Row } from "react-bootstrap";
import "./style.css";

interface Props {
  type?: "qr" | "bnpl" | "finpay";
}

const Footer: FC<Props> = ({ type }) => {
  const [showSaudiArabia, setShowSaudiArabia] = useState(false);
  const [showEgypt, setShowEgypt] = useState(false);
//   const [showUsa, setShowUsa] = useState(false);

  return (
    <div id="Footer" className={type ? `footer ${type}` : "footer"}>
      <div>
        <div className="title">
          <h2>Contact us</h2>
        </div>

		<div className="mobile-view-buttons">
          <button className="_Ubuntu-Medium" onClick={() => {
			setShowSaudiArabia(!showSaudiArabia)
			setShowEgypt(false)
			// setShowUsa(false)
		  }}>Saudi Arabia</button>
          <button className="_Ubuntu-Medium" onClick={() => {
			setShowEgypt(!showEgypt)
			setShowSaudiArabia(false)
			// setShowUsa(false)
		  }}>Egypt</button>
          {/* <button className="_Ubuntu-Medium" onClick={() => {
			setShowUsa(!showUsa)
			setShowEgypt(false)
			setShowSaudiArabia(false)
		  }}>USA</button> */}
        </div>

        <Row className="web-footer">
          <Col>
            <h2>Saudi Arabia </h2>
            <h6>Telephone: </h6>
            <p> +966 11 211 3300</p>
            <h6>E-mail: </h6>
            <p>info@sbs-sa.net</p>
            <h6>Address: </h6>
            <p>
            KSA-Riyadh- 7970 King Abdulaziz <br/>Alrabe 4478, Postal code: 13315
            </p>
          </Col>
          <Col>
            <h2>Egypt </h2>
            <h6>Telephone: </h6>
            <p>+20 11 432 571 17</p>
            <h6>E-mail: </h6>
            <p> info@bsas-eg.com</p>
            <h6>Address: </h6>
            <p>6 Degla st, Maadi, Cairo 11742</p>
          </Col>
          {/* <Col>
            <h2>USA </h2>
            <h6>Telephone: </h6>
            <p>+1 425 240-1532</p>
            <h6>E-mail: </h6>
            <p> info@bsas-eg.com</p>
            <h6>Address: </h6>
            <p>30 N GOULD St Ste R Sheridab, WY 82801</p>
          </Col> */}
          {/* <Col className="subscribe">
						<h2>Subscribe to our newletter</h2>
						<input type="email" placeholder="Enter your email address" />
						<div className="button">
							<Button className="sub">Subscribe</Button>
						</div>
					</Col> */}
        </Row>

          {showSaudiArabia && (
            <div>
              <h2 className="mobile-title" style={{ marginTop: '14px' }}>Saudi Arabia </h2>
              <h6 style={{ marginTop: '24px' }}>Telephone: </h6>
              <p> +966 11 211 3300</p>
              <h6>E-mail: </h6>
              <p>info@sbs-sa.net</p>
              <h6>Address: </h6>
              <p>
              KSA-Riyadh- 7970 King Abdulaziz <br/>Alrabe 4478, Postal code: 13315
              </p>
            </div>
          )}
          {showEgypt && (
            <div>
              <h2 className="mobile-title" style={{ marginTop: '14px' }}>Egypt </h2>
              <h6 style={{ marginTop: '24px' }}>Telephone: </h6>
              <p>+20 11 432 571 17</p>
              <h6>E-mail: </h6>
              <p> info@bsas-eg.com</p>
              <h6>Address: </h6>
              <p>6 Degla st, Maadi, Cairo 11742</p>
            </div>
          )}
          {/* {showUsa && (
            <div>
              <h2 style={{ marginTop: '14px' }}>USA </h2>
              <h6>Telephone: </h6>
              <p>+1 425 240-1532</p>
              <h6>E-mail: </h6>
              <p> info@bsas-eg.com</p>
              <h6>Address: </h6>
              <p>30 N GOULD St Ste R Sheridab, WY 82801</p>
            </div>
          )} */}
          {/* <Col className="subscribe">
						<h2>Subscribe to our newletter</h2>
						<input type="email" placeholder="Enter your email address" />
						<div className="button">
							<Button className="sub">Subscribe</Button>
						</div>
					</Col> */}
      </div>
    </div>
  );
};

export default Footer;