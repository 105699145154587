import React, { FC } from 'react';
import './style.css';

interface Props {
	type?: 'finpay';
	image: string;
	mobileFinbay: string;
}

const Featuers: FC<Props> = ({ type, image, mobileFinbay }) => {
	return (
		<div className={type ? `${type} featuers-container` : 'featuers-container'}>
			<div className="featuers-left">
				<h3>Easy</h3>
				<p>Slide and your payment is received</p>
				<h3>Fast</h3>
				<p>Receive your money in seconds</p>
				<h3>Hustle-free</h3>
				<p>It’s on your phone, that’s it</p>
			</div>
			<img className="web-finpay" src={image} alt="scan" />
			<img className="mobile-finpay" src={mobileFinbay} alt="scan" />
			<div className="featuers-right">
				<h3>Secure</h3>
				<p>All payments & transactions highly encrypted</p>
				<h3>Mobile</h3>
				<p>Get paid anywhere with your mobile phone</p>
				<h3>Contactless</h3>
				<p>No need to interact with others</p>
			</div>
		</div>
	);
};

export default Featuers;