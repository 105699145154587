import React from "react";
import { Button, Container, Nav, Navbar, NavDropdown } from "react-bootstrap";
import { Link } from "react-router-dom";
import "./style.css";
import Logo from "./../../assets/SBS-Logo.svg";
import QR from "./../../assets/qrMenu.svg";
import BNPL from "./../../assets/BNPL.svg";
import finbay from "./../../assets/finbay.svg";
import sliceTop from "./../../assets/sliceTop.svg";
import arrowDown from "../../assets/arrow-down.svg";

const CustomNav = () => {
  return (
    <div className="web-menu">
      <Navbar bg="light" expand="lg" className="pb-3 pt-3">
        <Container fluid className="nav-container">
          <Link className="logo-container" to="/">
            <img src={Logo} alt="logo" />
          </Link>
          <Navbar.Toggle aria-controls="navbarScroll" />
          <Navbar.Collapse id="navbarScroll">
            <Nav
              className="my-2 my-lg-0"
              style={{ maxHeight: "100px" }}
              navbarScroll
            >
              <Link className="navbar-links _Cairo-SemiBold" to="/services">
                Our services
              </Link>
              <span className="d-flex">
                <NavDropdown title="Our products" id="navbarScrollingDropdown">
                  <img src={sliceTop} alt="Slice" className="slice-top" />
                  <NavDropdown.Item>
                    <Link to="/qr" className="to-projects">
                      <div
                        className="menu-item"
                        style={{ marginBottom: "12px" }}
                      >
                        <img width="24px" height="24px" src={QR} alt="Qr" />
                        <p className="_Ubuntu-Medium">
                          QR Code Payment Solution
                        </p>
                      </div>
                    </Link>
                  </NavDropdown.Item>
                  <NavDropdown.Divider />
                  <NavDropdown.Item>
                    <Link to="/bnpl" className="to-projects">
                      <div className="menu-item" style={{ margin: "12px 0px" }}>
                        <img
                          width="57px"
                          height="16px"
                          src={BNPL}
                          alt="BNPL"
                          style={{ marginLeft: "-6px" }}
                        />
                        <p className="_Ubuntu-Medium">
                          Buy Now Pay Later Solution
                        </p>
                      </div>
                    </Link>
                  </NavDropdown.Item>
                  <NavDropdown.Divider />
                  <NavDropdown.Item>
                    <Link to="/finpay" className="to-projects">
                      <div className="menu-item" style={{ marginTop: "12px" }}>
                        <img
                          width="61px"
                          height="16px"
                          src={finbay}
                          alt="finbay"
                        />
                        <p className="_Ubuntu-Medium">
                          Buy Now Pay Later Solution
                        </p>
                      </div>
                    </Link>
                  </NavDropdown.Item>
                </NavDropdown>
                <img src={arrowDown} alt="arrow-down" />
              </span>

              <span className="d-flex about-us">
                <NavDropdown title="About us" id="navbarScrollingDropdown">
                  <img src={sliceTop} alt="Slice" className="slice-top" />
                  <NavDropdown.Item>
                    <Link className="navbar-links _Cairo-SemiBold" to="/about">
                      About us
                    </Link>
                  </NavDropdown.Item>
                  <NavDropdown.Divider />
                  <NavDropdown.Item>
                    <Link
                      className="navbar-links _Cairo-SemiBold"
                      to="/about/our-team"
                    >
                      Our Team
                    </Link>
                  </NavDropdown.Item>
                  <NavDropdown.Divider />
                  <NavDropdown.Item>
                    <Link
                      className="navbar-links _Cairo-SemiBold"
                      to="/about/our-values"
                    >
                      Our Values
                    </Link>
                  </NavDropdown.Item>
                  <NavDropdown.Divider />
                  <NavDropdown.Item>
                    <Link
                      className="navbar-links _Cairo-SemiBold"
                      to="/about/our-customers"
                    >
                      Our Customers
                    </Link>
                  </NavDropdown.Item>
                  <NavDropdown.Divider />
                  <NavDropdown.Item>
                    <Link
                      className="navbar-links _Cairo-SemiBold"
                      to="/about/our-partners"
                    >
                      Our Partners
                    </Link>
                  </NavDropdown.Item>
                </NavDropdown>
                <img src={arrowDown} alt="arrow-down" />
              </span>
            </Nav>
          </Navbar.Collapse>
              <Link className="navbar-links _Cairo-SemiBold" to="/contact">
                Contact us
              </Link>
          {/* <Button className="lang-btn _Cairo-SemiBold">العربية</Button> */}
        </Container>
      </Navbar>
    </div>
  );
};

export default CustomNav;
