import React from "react";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import { valuesIcons, customersIcons } from "../../_mockup";
import WebMenu from "../../components/WebMenu";
import MobileMenu from "../../components/MobileMenu";
import Footer from "../../components/Footer";
import SbsLogo from "./../../assets/sbs.png";
import IconCard from "../../components/IconCard";
import "react-tabs/style/react-tabs.css";
import "./style.css";
import visa from '../../assets/visa.svg';
import masterCard from '../../assets/mastercard.svg';
import oracle from '../../assets/oracle.svg';
import express from '../../assets/express.svg';
import Thales from '../../assets/Thales.svg'
import ibm from '../../assets/ibm.svg'
import odoo from '../../assets/odoo.svg'
import Trust from '../../assets/Trust.svg'
import Adobe from '../../assets/Adobe.svg'
import oneSpan from '../../assets/oneSpan.svg';
import rendition from '../../assets/rendition.svg'
import talend from '../../assets/talend.svg'
import skeps from '../../assets/skeps.svg';

import stc from '../../assets/stc.svg';
import rajhi from '../../assets/rajhi.svg';
import qnb from '../../assets/QNB.svg';
import Albilad from '../../assets/Albilad.svg';
import Alinmaa from '../../assets/Alinmaa.svg';
import HydroComp from '../../assets/Hydro-Comp.svg';
import Sanad from '../../assets/Sanad.svg';
import channels4 from '../../assets/channels4.svg';
import kingsalman from '../../assets/king-salman.svg';
import manga from '../../assets/manga.svg';
import EdfaPay from '../../assets/EdfaPay.svg';
import Soreal from '../../assets/Soreal.svg';
import snk from '../../assets/snk.svg';
import Solutions_by_STC from '../../assets/Solutions_by_STC.svg';
import financial from '../../assets/financial.svg'

const OurTeam = () => {
  return (
    <div className="about">
      <WebMenu />
      <MobileMenu type='' />
      <header className="about-header">
        <img src={SbsLogo} alt="Sbs logo" />
      </header>
      <div className="tabs-conatiner">
        <Tabs defaultIndex={1}>
          <TabList>
            <Tab>About Us</Tab>
            <Tab>Our Team</Tab>
            <Tab>Our Values</Tab>
            <Tab>Our Customers</Tab>
            <Tab>Our Partners</Tab>
          </TabList>

          <TabPanel>
            <h4 className="_Ubuntu-Bold">About Us</h4>
            <p style={{ color: '#03151F' }} className='_Ubuntu-Regular'>
              SBS is an industry leading professional services solutions. Our
              aim is to deliver 100% quality product <br /> and success your
              business to achieve ultimate goal
            </p>
            <h4 className="_Ubuntu-Bold" style={{ fontSize: '24px', color: '#204269', marginTop:'34px' }}>Our Success</h4>
            <p style={{ color: '#000' }} className='_Ubuntu-Regular'>
              Each of our projects starts with the simple question “How will you
              measure the success of this project?” Success is critical to our
              team; whether that success comes in the form of happy clients,
              loyal site users or solid metrics. To help us better understand
              the challenges of your project we try to establish not only the
              goals but what are your current returns, challenges and the
              opportunities for growth. Our approach has yielded many successful
              products and happy customers over the years.
            </p>
            <br />
            <h4 className="_Ubuntu-Bold" style={{ fontSize: '24px', color: '#204269', marginTop:'34px' }}>Our Difference</h4>
            <p style={{ color: '#000' }} className='_Ubuntu-Regular'>
              We view clients as partners, and our success is only measured by the success of our partners. So, we put it all on the table in order to exceed expectations. We know each new project is a gamble, but there is no one we’d rather bet on than ourselves. At SBS Software, our culture matters. And it reflects in our work. Here, jobs are careers, not just a way to pay the bills. The finished product matters to us because it carries our name. Our staff is young, energetic and innovative, and we are never afraid to take risks in an effort to help our clients. Every member of our team is passionate about the programming, web as a communications medium as well as a marketplace. For every project, no matter how large or how small, we strive to not only meet your needs, but deliver a showcase in your field.
            </p>
          </TabPanel>
          <TabPanel className="our-team">
            <h4 className='_Ubuntu-Bold'>Our Team</h4>
            <p style={{ color: '#000' }}>
              SBS team consists of highly qualified consultants, computer
              engineers, hardware technical consultants, graphic designers,
              developers, and professional certified project managers. This team
              has helped companies in the mobility world to carry on their work
              successfully and establish trust with our brand.
            </p>
            <p style={{ color: '#000' }}>
              The people that make up SBS have years of experience working with
              some of the largest brands on the market today.
            </p>
            <p style={{ color: '#000' }} >
              SBS team is one of the most homogeneous and professional. All of
              its members are working in harmony offering their utmost loyalty
              and dedications.
            </p>
            <p style={{ color: '#000' }}>
              SBS team have gone beyond products and technology and decided to
              offer the localization knowledge and technology as the added value
              of any product and/or service that SBS well provide.
            </p>
            <h4 className='_Ubuntu-Bold' style={{ color: '#204269', marginTop: '32px', fontSize: '24px' }}>Our Company team experiences</h4>
            <ul>
              <li className='_Ubuntu-Regular'>Change Management and Enterprise development</li>
              <li className='_Ubuntu-Regular'>Experienced IT people in the field of mobility</li>
              <li className='_Ubuntu-Regular'>Business Process Mapping, Automation and Optimization</li>
              <li className='_Ubuntu-Regular'>Software Development</li>
              <li className='_Ubuntu-Regular'>Project Management Experts</li>
              <li className='_Ubuntu-Regular'>Fintech and Digital payment</li>
              <li className='_Ubuntu-Regular'>Digital and technical transformation</li>
              <li className='_Ubuntu-Regular'>Consulting and Outsourcing</li>
              <li className='_Ubuntu-Regular'>System integration</li>
            </ul>
          </TabPanel>
          <TabPanel>
            <h4 className="_Ubuntu-Bold">Our Values</h4>
            <p className="_Ubuntu-Regular values" style={{ color: '#000', width:'59%' }}>
              SBS values are the cornerstone of how we do business and the basis
              for our guiding principles and the culture of the company. They
              set the benchmark for minimum expectations and are the driving
              force behind our clear communication, superior performance, and
              professional integrity.
            </p>
            <div className="value-cards">
              {valuesIcons.map((val) => (
                <IconCard icon={val.icon} title={val.title} key={val.id} />
              ))}
            </div>
          </TabPanel>
          <TabPanel>
            <h4 className="_Ubuntu-Bold">Our Customers</h4>
            <p className="_Ubuntu-Regular customers-title" style={{ color: '#000', width:'59%' }}>
            SBS values are the cornerstone of how we do business and the basis for our guiding principles and the culture of the company. They set the benchmark for minimum expectations and are the driving force behind our clear communication, superior performance, and professional integrity.
            </p>
            <div className="load-more-container">
              <input type="checkbox" id="load-more" />
              <ul className="value-cards customers">
                <li>
                  <div className="icon-card-container">
                    <img className='animate__animated animate__fadeIn animate__delay-2s' src={stc} alt="stc" />
                  </div>
                </li>
                <li>
                  <div className="icon-card-container">
                    <img className='animate__animated animate__fadeIn animate__delay-2s' src={rajhi} alt="rajhi" />
                  </div>
                </li>
                <li>
                  <div className="icon-card-container">
                    <img className='animate__animated animate__fadeIn animate__delay-2s' src={qnb} alt="qnb" />
                  </div>
                </li>
                <li>
                  <div className="icon-card-container">
                    <img className='animate__animated animate__fadeIn animate__delay-2s' src={Albilad} alt="Albilad" />
                  </div>
                </li>
                <li>
                  <div className="icon-card-container">
                    <img className='animate__animated animate__fadeIn animate__delay-2s' src={Alinmaa} alt="Alinmaa" />
                  </div>
                </li>
                <li>
                  <div className="icon-card-container">
                    <img className='animate__animated animate__fadeIn animate__delay-2s' src={HydroComp} alt="Hydro-Comp" />
                  </div>
                </li>
                <li>
                  <div className="icon-card-container">
                    <img className='animate__animated animate__fadeIn animate__delay-2s' src={Sanad} alt="Sanad" />
                  </div>
                </li>
                <li>
                  <div className="icon-card-container">
                    <img className='animate__animated animate__fadeIn animate__delay-2s' src={channels4} alt="channels4" />
                  </div>
                </li>
                <li>
                  <div className="icon-card-container">
                    <img className='animate__animated animate__fadeIn animate__delay-2s' src={kingsalman} alt="king-salman" />
                  </div>
                </li>
                <li>
                  <div className="icon-card-container">
                    <img className='animate__animated animate__fadeIn animate__delay-2s' src={manga} alt="manga" />
                  </div>
                </li>
                <li>
                  <div className="icon-card-container">
                    <img className='animate__animated animate__fadeIn animate__delay-2s' src={EdfaPay} alt="EdfaPay" />
                  </div>
                </li>
                <li>
                  <div className="icon-card-container">
                    <img className='animate__animated animate__fadeIn animate__delay-2s' src={Soreal} alt="Soreal" />
                  </div>
                </li> 
                <li>
                  <div className="icon-card-container">
                    <img className='animate__animated animate__fadeIn animate__delay-2s' src={snk} alt="snk" />
                  </div>
                </li> 
                <li>
                  <div className="icon-card-container">
                    <img className='animate__animated animate__fadeIn animate__delay-2s' src={Solutions_by_STC} alt="Solutions_by_STC" />
                  </div>
                </li>
                <li>
                  <div className="icon-card-container">
                    <img className='animate__animated animate__fadeIn animate__delay-2s' src={financial} alt="financial" />
                  </div>
                </li>
              </ul>
              <label className="load-more-btn" htmlFor="load-more">
                <span className="unloaded show-more-customers-btn _Ubuntu-Medium">
                  Show More
                </span>
                <span className="loaded show-more-customers-btn _Ubuntu-Medium">
                  Show Less
                </span>
              </label>
            </div>
          </TabPanel>
          <TabPanel>
            <h4 className="_Ubuntu-Bold">Our partners</h4>
            <p className="_Ubuntu-Regular partners-title" style={{ color: '#000', width:'90%' }}>
            We are proud to work with bold and visionary partners, helping us shape an impressive future led by technical solutions.
            </p>
            <div className="load-more-container">
              <input type="checkbox" id="load-more" />
              <ul className="value-cards customers">
                <li>
                  <div className="icon-card-container">
                    <img className='animate__animated animate__fadeIn animate__delay-2s' src={visa} alt="visa" />
                  </div>
                </li>
                <li>
                  <div className="icon-card-container">
                    <img className='animate__animated animate__fadeIn animate__delay-2s' src={masterCard} alt="masterCard" />
                  </div>
                </li>
                <li>
                  <div className="icon-card-container">
                    <img className='animate__animated animate__fadeIn animate__delay-2s' src={oracle} alt="oracle" />
                  </div>
                </li>
                <li>
                  <div className="icon-card-container">
                    <img className='animate__animated animate__fadeIn animate__delay-2s' src={express} alt="express" />
                  </div>
                </li>
                <li>
                  <div className="icon-card-container">
                    <img className='animate__animated animate__fadeIn animate__delay-2s' src={Thales} alt="Thales" />
                  </div>
                </li>
                <li>
                  <div className="icon-card-container">
                    <img className='animate__animated animate__fadeIn animate__delay-2s' src={ibm} alt="ibm" />
                  </div>
                </li>
                <li>
                  <div className="icon-card-container">
                    <img className='animate__animated animate__fadeIn animate__delay-2s' src={odoo} alt="odoo" />
                  </div>
                </li>
                <li>
                  <div className="icon-card-container">
                    <img className='animate__animated animate__fadeIn animate__delay-2s' src={Trust} alt="Trust" />
                  </div>
                </li>
                <li>
                  <div className="icon-card-container">
                    <img className='animate__animated animate__fadeIn animate__delay-2s' src={Adobe} alt="Adobe" />
                  </div>
                </li>
                <li>
                  <div className="icon-card-container">
                    <img className='animate__animated animate__fadeIn animate__delay-2s' src={rendition} alt="rendition" />
                  </div>
                </li>
                <li>
                  <div className="icon-card-container">
                    <img className='animate__animated animate__fadeIn animate__delay-2s' src={talend} alt="talend" />
                  </div>
                </li>
                <li>
                  <div className="icon-card-container">
                    <img className='animate__animated animate__fadeIn animate__delay-2s' src={skeps} alt="skeps" />
                  </div>
                </li>
              </ul>
              <label className="load-more-btn" htmlFor="load-more">
                <span className="unloaded show-more-customers-btn _Ubuntu-Medium">
                  Show More
                </span>
                <span className="loaded show-more-customers-btn _Ubuntu-Medium">
                  Show Less
                </span>
              </label>
              </div>
          </TabPanel>
        </Tabs>
      </div>
      <Footer />
    </div>
  );
};

export default OurTeam;
