import React from "react";
import WebMenu from "../../components/WebMenu";
import MobileMenu from '../../components/MobileMenu'
import Footer from "../../components/Footer";
import FormCard from "../../components/FormCard";
import Slider from "../../components/Slider";
import Intro from '../../components/Intro';
import Amico from "./../../assets/touch-amico.svg";
import "./style.css";

const Home = () => {
  return (
    <div className="home"> 
      <WebMenu />
      <MobileMenu type=''/>
      <Intro />
      <div className="each-container">
        <h2>Welcome to SBS</h2>
        <p>
          Each of our projects start with the simple question
          <br />{" "}
          <span>“How will you measure the success of this project?”</span>{" "}
          <br /> <p style={{ marginTop: '14px' }}>
          Success is critical to our team; whether that success comes in
          the
          <br /> form of happy clients, loyal site users or solid metrics. To
          help us
          <br /> better understand the challenges of your project we try to
          establish
          <br /> not only the goals, but also what are your current returns,
          challenges
          <br /> and the opportunities for growth. Our approach has yielded many
          <br /> successful products and happy customers over the years.
          </p>
        </p>
      </div>
      <Slider />
      <FormCard
        title="Get in touch 👋🏻"
        content="Just enter your contact info and we will get in touch as soon as possible"
        haveImage
        image={Amico}
      />
      <Footer />
    </div>
  );
};

export default Home;
