import "bootstrap/dist/css/bootstrap.min.css";
import React from "react";
import "./App.css";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Home from "./screens/Home";
import Services from "./screens/Services";
import Products from "./screens/Products";
import AboutUs from "./screens/AboutUs";
import OurTeam from "./screens/AboutUs/OurTeam";
import OurValues from "./screens/AboutUs/OurValues"
import OurCustomers from './screens/AboutUs/OurCustomers';
import OurPartners from './screens/AboutUs/OurPartners';
import ContactUs from "./screens/ContactUs";
import QrProject from "./screens/QrProject";
import Bnpl from "./screens/BNPL";
import Finpay from "./screens/Finbay";
import AboutUsMobileView from './screens/AboutUs/AboutUsMobileView';
import ContactUsMobileView from './screens/ContactUs/ContactUsMobileView'

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/services" element={<Services />} />
        <Route path="/products" element={<Products />} />
        <Route path="/about" element={<AboutUs />} />
        <Route path="/about/our-team" element={<OurTeam />} />
        <Route path="/about/our-values" element={<OurValues />} />
        <Route path="/about/our-customers" element={<OurCustomers />} />
        <Route path="/about/our-partners" element={<OurPartners />} />
        <Route path="/contact" element={<ContactUs />} />
        <Route path="/qr" element={<QrProject />} />
        <Route path="/bnpl" element={<Bnpl />} />
        <Route path="/finpay" element={<Finpay />} />
        <Route path="/about-us" element={<AboutUsMobileView />} />
        <Route path="contact-us"  element={<ContactUsMobileView />}/>
      </Routes>
    </BrowserRouter>
  );
}

export default App;
