import React from "react";
import { Button, Col, Container, Row } from "react-bootstrap";
import Footer from "../../components/Footer";
import IconCard from "../../components/IconCard";
import ProjectNav from "../../components/ProjectNav";
import { finpayIcons, qrIcons } from "../../_mockup";
import visa from "./../../assets/qrIcons/visa.svg";
import master from "./../../assets/qrIcons/master.svg";
import mada from "./../../assets/qrIcons/mada.svg";
import History from "./../../assets/finpayIcons/History.svg";
import Join from "./../../assets/finpayIcons/Join.svg";
import Fees from "./../../assets/finpayIcons/Fees.svg";
import Server from "./../../assets/finpayIcons/Server.svg";
import merchant from "./../../assets/finpayIcons/marchent.svg";
import Scene from "./../../assets/finpayIcons/Scene.svg";
import phone from "./../../assets/phone.png";
import easy from "./../../assets/finpayIcons/easy.png";
import "./style.css";
import FormCard from "../../components/FormCard";
import Featuers from "../../components/Featuers";
import MobileMenu from "../../components/MobileMenu";
import card1 from '../../assets/card-1.png'
import card2 from '../../assets/card-2.png'
import card3 from '../../assets/card-3.png'
import finpayBkImg from '../../assets/finpay-top-bk.png';
import mobileFinbay from '../../assets/finpay-mobile-Mockup.png';
import phoneWeb from '../../assets/finpayIcons/easy.png'

const Finpay = () => {
  return ( 
    <div className="finpay-project">
      <ProjectNav type="finpay" />
      <MobileMenu type='finpay' />
      <header className="finpay-header">
        <h2>
          <span>FinPay</span>
          <span>is out there now</span>
        </h2>
        <p>
          Accept all your business payments anywhere & anytime through your{" "}
          <br /> mobile phone with the SoftPOS mobile app, contactlessly,
          securely, <br /> and fast, just as simple as that 👌
        </p>
        <div className='finpay-download-btn'>
          <Button>Download Now</Button>
        </div>
      </header>
      <div className="solution-container">
        <div>
          <h2>FinPay solution</h2>
          <p>
            FinPay is a SoftPOS mobile app designed and developed to fulfill
            your <br /> business payment needs. Our SoftPOS solution can easily
            facilitate <br /> your transactions, manage your payments, all on
            your mobile device <br /> through the same application replacing POS
            machines
          </p>
        </div>
       <div className='finpay-web'>
        <img width='250px' src={phoneWeb} alt="web" />
       </div>
       <div className='finpay-mob'>
        <img src={phone} alt="mob" />
       </div>
      </div>
      <div className="for-whom">
        <h2>For whom</h2>
        <h6>
          FinPay solution is for any business can accept digital payments <br />
          We believe that whatever your business is, FinPay suits you. <br />
          Even if your business is...
        </h6>
        <div className="whom-container">
          {finpayIcons.map((icon) => (
            <IconCard key={icon.id} icon={icon.icon} title={icon.title} />
          ))}
        </div>
      </div>
      <div className="payment-container">
        <Container>
          <Row>
            <Col className="cards-container">
              <h2>FinPay is certified by</h2>
              <div className="bank-cards">
                {/* <img src={mada} alt="mada" /> */}
                <img className='animate__animated animate__fadeIn animate__delay-2s' src={visa} alt="visa" />
                <img className='animate__animated animate__fadeIn animate__delay-2s' src={master} alt="master" />
              </div>
              <div className="bank-cards-mobile">
                {/* <img src={card1} alt="mada" /> */}
                <img className='animate__animated animate__fadeIn animate__delay-2s' src={card2} alt="visa" />
                <img className='animate__animated animate__fadeIn animate__delay-2s' src={card3} alt="master" />
              </div>
            </Col>
            <Col style={{ textAlign: 'center' }}>
              <img className='animate__animated animate__fadeIn animate__delay-2s' src={merchant} alt="merchant" />
            </Col>
          </Row>
        </Container>
      </div>
      <Featuers image={easy} type="finpay" mobileFinbay={mobileFinbay} />
      <div className="how-work why-qr">
        <h2>Why FinPay</h2>
        <p>
          With FinPay one of the biggest benefits for merchants is the ability
          to <br /> get up & running and start accepting payments once the app
          is <br /> downloaded and the merchant account is activated
        </p>
        <div className="work-item-container">
          <div className="work-item">
            <img src={Join} alt="Join" />
            <h5>Easy to join</h5>
            <h6>
              Registration process takes
              <br />
              no time
            </h6>
          </div>
          <div className="work-item">
            <img src={Fees} alt="Fees" />
            <h5>No extra fees</h5>
            <h6>
              No hidden taxes <br />
              no extra charges
            </h6>
          </div>
          <div className="work-item">
            <img src={History} alt="History" />
            <h5>Payments history</h5>
            <h6>
              Easily check your transactions <br />
              and payments history
            </h6>
          </div>
          <div className="work-item">
            <img src={Server} alt="Server" />
            <h5>Always available</h5>
            <h6>
              100% Up-time, no bugs, <br />
              no server downtime
            </h6>
          </div>
        </div>
      </div>

      <img className='finpay-register-img animate__animated animate__fadeIn animate__delay-2s' src={finpayBkImg} alt='finpay-bk-img' />

      <FormCard
        title={"Register to FinPay"}
        content={
          "Register now & start your new era of receiving payments"}
        haveImage
        btnTitle='Register'
        image={Scene}
        type="finpay"
      />
      <Footer type="finpay" />
    </div>
  );
};

export default Finpay;
