import React from 'react';
import './style.css';

const Intro = () => {
	return (
		<header className="home-header">
			<h2 className='_Ubuntu-Regular'>
				We innovate <br /> We deliver <br /> <span className='_Ubuntu-Bold'>You succeed</span>
			</h2>
		</header>
	);
};

export default Intro;