import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";

// import required modules
import { Autoplay, Pagination, Navigation } from "swiper";
import WebMenu from "../../components/WebMenu";
import MobileMenu from "../../components/MobileMenu";
import Footer from "../../components/Footer";
import Intro from "../../components/Intro";
import "./style.css";

const Services = () => {
  return (
    <div className="services">
      <WebMenu />
      <MobileMenu type="" />
      <Intro />
      <div className="service-section">
        <Swiper
          spaceBetween={30}
          centeredSlides={true}
          pagination={{
            clickable: true,
          }}
          navigation={true}
          modules={[Autoplay, Pagination, Navigation]}
          className="mySwiper"
        >
          <SwiperSlide>
            <div className="bk">
              <h1 className="_Ubuntu-Bold">Backend development</h1>
              <ul>
                <li>
                  Web Development Languages: backend engineer works with at
                  least one server-side or Backend programming language like
                  Java, Python, Ruby, . Net etc.
                </li>
                <li>
                  Database and Cache: a full knowledge of various DBMS
                  technology.
                </li>
                <li>
                  Servers: Exposure to handling Apache, Nginx, IIS servers,
                  Microsoft IIS.
                </li>
                <li>Ability to write quality unit tests.</li>
                <li>
                  Knowledge of Algorithms and data structures is also an
                  essential need for any professional full stack developer.
                </li>
                <li>
                  An awareness of security concerns is important, as each layer
                  has its vulnerabilities.
                </li>
                <li>
                  Should know the differences between multiple delivery
                  platforms like mobile vs. desktop.
                </li>
                <li>
                  Basic exposure to frontend techs like HTML and CSS is
                  desirable.
                </li>
                <li>
                  Knowledge of session management in a distributed server
                  environment.
                </li>
                <li>
                  Devops: DevOps is a set of practices that combines software
                  development and IT operations. It aims to shorten the systems
                  development life cycle and provide continuous delivery with
                  high software quality.
                </li>
              </ul>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className="fe">
              <h1 className="_Ubuntu-Bold">Frontend development</h1>
              <ul>
                <li>
                  Site/Software Development: the Frontend team develops code on
                  responsive sites across multiple browsers, tablets, and other
                  internet accessing devices such as phones in order to ensure
                  accessibility of the site from multiple devices.
                </li>
                <li>
                  User Experience/User Interface: The Frontend team plays a
                  leading role in the translation of the user interface/user
                  experience design wireframes into actual code that produces
                  visual elements of the website/mobile application in progress.
                  We do this by applying responsive user interface design
                  principles and ensuring the technical feasibility of these
                  user interface designs for the best user experience possible.
                </li>
                <li>
                  Troubleshooting and Testing: The Frontend team is tasked with
                  defining, designing, and executing usability testing of
                  sites/software. We also review and coordinate feedback
                  throughout the site/software development lifecycle where we
                  ensure that all input is valid prior to submitting it to the
                  backend team.
                </li>
                <li>
                  Collaboration: The Frontend team liaises with the backend team
                  , ensuring accuracy and synchrony in both the frontend and
                  backend development at all stages of the site/software
                  development.
                </li>
                <li>
                  In this collaborative role, the Frontend team will also work
                  with the user interface department in designing and developing
                  prototypes for usability testing of site and software
                  features.
                </li>
              </ul>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className="ux-design">
              <h1 className="_Ubuntu-Bold">UX\UI Design</h1>
              <ul>
                <li>Focus on usability.</li>
                <li>Maximize functionality.</li>
                <li>Give the user a delightful experience.</li>
                <li>Enhance user control.</li>
                <li>Increase comfort levels.</li>
                <li>Reduce cognitive load (unnecessary activities).</li>
                <li>
                  User interface design is one of the main ways to increase your
                  website’s traffic and therefore move towards higher
                  conversation rates.
                </li>
                <li>
                  UI design captivates your audience and persuade them to stay
                  on your site and take a deeper look.
                </li>
                <li>
                  As more business have been forced to move to an online
                  presence duo to COVID-19 restrictions, your user interface
                  design now has the potential to make or break your company.
                </li>
                <li>
                  UX tries to fulfill the user’s needs and builds a better
                  customer satisfaction–conversion–retention journey.
                </li>
                <li>
                  UX aims to provide positive experiences to the user that keeps
                  them loyal to the product or brand.
                </li>
                <li>
                  UX defines customer journeys on your product and establishes a
                  two-way relationship between the maker and the user.
                </li>
                <li>
                  UX reduces costs for development/bug fixing/marketing and so
                  on.
                </li>
                <li>
                  UX provides improved return on investment (ROI) Sometimes the
                  product doesn’t need to be innovative. It simply takes the
                  usual idea and represents it differently. The user-focused
                  design makes the product stand out.
                </li>
                <li>
                  UX helps provide intuitive experience, coherence & continuity
                  and platform-specific designs.
                </li>
              </ul>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className="SBS-SAP">
              <h1 className="_Ubuntu-Bold">SBS SAP PO/PI Services</h1>
              <ul>
                <li>
                  We have the technical expertise in SAP Process Orchestration
                  skills Strong hands-on experience in developing custom
                  Interfaces with SAP Cloud platform Integration (HCI) along
                  with configuring standard content for Cloud Solutions (SFSF,
                  ARIBA, C4C, Concur, Fieldglass)
                </li>
                <li>
                  As we deal with the newest SAP Process Integration product
                  releases such as Process Integration and Process
                  Orchestration, versions 7.3x, 7.4 and 7.5 Expert hands-on
                  experience in developing complex mapping (Graphical and Java),
                  Runtime workbench, Monitoring & Troubleshooting, ALE
                  Configuration, Netweaver Business Process Management (BPM) and
                  Business Rule Management (BRM), Security concepts and API
                  management.
                </li>
                <li>
                  We own also the Excellent software development skills either
                  in ABAP or Java, Understanding of JAVA J2EE , ODATA, Rest and
                  SOAP based services.
                </li>
                <li>
                  SBS can deliver projects for customers, including support
                  during requirement gathering phase, analysis of system
                  landscape, technical design, and development of interface.
                </li>
                <li>
                  Worked in various business scenario (A2A and B2B) and
                  experience in working on all major adapters.
                </li>
                <li>
                  After gathering documents and analyzes system and business
                  requirements for moderately complex SAP applications, we
                  deliver the projects for customers, including support during
                  requirement gathering phase, analysis of system landscape,
                  technical design, and development of interface.
                </li>
              </ul>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className="outsource">
              <h1 className="_Ubuntu-Bold">Outsourcing</h1>
              <p>
                Our Outsourcing department is 10+ years professional experience
                in outsourcing/offshoring focusing on Software & IT positions.
                Hands-on & extensive experience in all Recruitment life cycle
                phases further to many industries FinTech , Startups and
                consultation firms .
              </p>
              <ul>
                <li>
                  Hired: Developers Backend, Front End , Fullstack , MERN Stack
                  for Java , ASP.Net , Python , C++ ,Android , IOS , Flutter,
                  Angular , NodeJS , JavaScript , React Native and …etc.
                </li>
                <li>In addition to Outsystems (low code platforms )</li>
                <li>Project Managers , Agile , Scrum</li>
                <li>
                  Quality ( Testing ) Manual , Automation and Quality Assurance
                  CMMI{" "}
                </li>
                <li>
                  Business analyst , Solution Architect , ERP resources ( SAP ,
                  Oracle , MS Dynamics , Odoo ){" "}
                </li>
                <li>DevOps , Operations support , Cloud , RPA</li>
                <li>
                  Data DWH , Big Data , Data scientist , BI , Data Quality
                </li>
                <li>
                  Integration Tibco , webmethods , Redhat fuse , Fiorano ,
                  Message broker
                </li>
                <li>Infrastructure Network , security and systems</li>
                <li>
                  Sales and presales specialized in selling custom software .
                </li>
              </ul>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className="odo">
              <h1 className="_Ubuntu-Bold">Odoo</h1>
              <ul>
                <li>
                  Odoo Migration helps you to suit according to the trend and
                  achieve success most efficiently. As with the name itself.
                </li>
                <li>
                  Odoo Migration is the process of migration to a newer version
                  of Odoo from the old one, When the trend of market changes we
                  put ourselves updated with the latest efficient trends as it
                  leads to success.{" "}
                </li>
                <li>
                  it’s possible to increase the efficiency and functionality of
                  Odoo by integrating third-party software. As every second
                  contributes to the discovery and implementation of new
                  technology, we will have to keep ourselves vigilant and sharp
                  to know the latest and develop
                </li>
                <li>
                  Odoo is one of the best enterprise bundles with a flexible
                  open-source application. Therefore, with the expertise of Odoo
                  mobile app development, we are ready to explore more on mobile
                  apps. With the integration of Odoo in mobile Apps, your
                  desired mobile application solution reached the goal.
                </li>
              </ul>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className="Oracle">
              <h1 className="_Ubuntu-Bold">Oracle</h1>
              <ul>
                <li>
                  Scalability and Performance: Features like Real Application
                  Clustering and Portability make an Oracle database scalable
                  according to the usage. In a multiuser database, it is
                  required to control data consistency and concurrency which are
                  contemplated by Oracle.
                </li>
                <li>
                  Availability: Real-time applications require high data
                  availability. High performing computing environments are
                  configured to provide all-time data availability.
                </li>
                <li>
                  Backup and Recovery: Its layout complete recovery features to
                  recover data from almost all kinds of failures, In case of
                  failure, the debase needs to be recovered within no time for
                  high availability. Unaffected parts of data are available
                  while the affected ones are getting recovered.
                </li>
                <li>
                  Security: securing the data is always the top of priority.
                  Oracle provides mechanisms to control data access and usage.
                  Implementing authorization and editing user actions can
                  prevent unauthorized access and allow distinct access to the
                  users.
                </li>
              </ul>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className="qc">
              <h1 className="_Ubuntu-Bold">Quality Assurance</h1>
              <ul>
                <li>
                  Data testing services: Test your data for quality and
                  effectiveness.
                </li>
                <li>
                  Regression testing services: Detect and eliminate bugs and
                  errors.
                </li>
                <li>
                  Security services: Check your product’s security
                  vulnerabilities and protect its trust.
                </li>
                <li>
                  Performance testing: Test whether your product is ready to
                  meet superior customer experience.
                </li>
                <li>
                  QA transformation: A QA of your QA strategy, tools and
                  modules.
                </li>
                <li>
                  QA automation: Achieve cost-effectiveness through automated QA
                  processes.
                </li>
                <li>
                  Predictive analytics: Early detection of errors and bugs for
                  faster delivery to the market.
                </li>
              </ul>
            </div>
          </SwiperSlide>
        </Swiper>
      </div>
      <Footer />
    </div>
  );
};

export default Services;
