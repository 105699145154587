import React, { useRef, useState } from "react";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation";
import homeHeader from "./../../assets/homeHeader.png";
import MaskedHeroImag1 from "./../../assets/MaskedHeroImag1.png";
import MaskedHeroImag2 from "./../../assets/MaskedHeroImag2.png";
import MaskedHeroImag3 from "./../../assets/MaskedHeroImage3.png";
import slide1 from "./../../assets/slide1.png";
import "./style.css";

// import required modules
import { Navigation } from "swiper";

export default function Slider() {
  return (
    <>
      <Swiper navigation={true} modules={[Navigation]} className="mySwiper">
        <SwiperSlide>
          <h1>SBS Vision</h1>
          <h4>
            to be the most trusted business solutions &<br /> technologies
            provider
          </h4>
        </SwiperSlide>
      </Swiper>
    </>
  );
}
